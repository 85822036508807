export const MODES = [
  'SSB',
  'AM',
  'FM',
  'CW',
  'RTTY',
  'PSK31',
  'PSK63',
  'JT9',
  'JT65',
  'PACKET',
  'AMTOR',
  'PACTOR',
  'SSTV',
  'MT63',
  'OLIVIA',
  'Other',
]

export default MODES
